import { render, staticRenderFns } from "./AuthHeader.vue?vue&type=template&id=03de2288&scoped=true&"
import script from "./AuthHeader.vue?vue&type=script&lang=js&"
export * from "./AuthHeader.vue?vue&type=script&lang=js&"
import style0 from "./AuthHeader.vue?vue&type=style&index=0&id=03de2288&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03de2288",
  null
  
)

export default component.exports