<template>
  <div>
    <div class="d-flex flex-column">
      <span class="type-profile">Editar Usuário</span>
    </div>
    <div>
      <app-loading :active="loading"></app-loading>
      <v-form ref="form" v-model="hableSubmit">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="userData.first_name"
              :rules="[rules.required]"
              label="Primeiro Nome"
              required
              dark
              class="fluid"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="userData.last_name"
              :rules="[rules.required]"
              label="Segundo Nome"
              required
              dark
              class="fluid"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="userData.email"
              :rules="[rules.required, rules.email]"
              label="Email"
              required
              dark
              class="fluid"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="userData.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="password"
              dark
              label="Senha"
              @click:append="show = !show"
              class="fluid"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-text-field
              v-model="metabase_password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="metabase_password"
              dark
              label="Senha Metabase (Apenas para acesso ao Metabase)"
              @click:append="show = !show"
              class="fluid"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" md="3">
            <v-select
              v-model="selectedUserGroups"
              :items="userGroups"
              item-text="name"
              item-value="id"
              label="Grupo de Usuário"
              multiple
              dark
            >
            </v-select>
          </v-col>
        </v-row>
        <div class="mt-12 d-flex justify-end">
          <!-- <v-btn
            class="mr-4 clear-button"
            outlined
            dark
            color="white"
            height="40"
            @click="reset"
          >
            Limpar
          </v-btn> -->
          <v-btn
            :disabled="!hableSubmit"
            class="submit-button"
            height="40"
            @click="submit"
          >
            <span v-if="loading">Salvando</span>
            <span v-else>Salvar</span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <app-notification
      @notificationStatus="changeNotificationStatus"
      :active="notification.active"
      :type="notification.type"
      :message="notification.message"
    >
    </app-notification>
  </div>
</template>

<script>
import { getUserById } from "../services/User";
import { putUser } from "../services/User";
import { getUserGroups } from "../services/UserGroups";
import PageLoading from "../components/PageLoading.vue";
import Notification from "../components/Notification.vue";
export default {
  components: {
    "app-loading": PageLoading,
    "app-notification": Notification,
  },
  data: () => ({
    loading: false,
    hableSubmit: true,
    firstName: "",
    secondName: "",
    email: "",
    password: "",
    metabase_password: "",
    type: "",
    typeUser: localStorage.tp_profile,
    types: [],
    userGroups: [],
    selectedUserGroups: [],
    basicInformation: {
      interpreter: "",
      mainGenre: "",
      secondaryGenre: "",
      typeOfPoetryOnYoutube: "",
      biography: "",
      idSpotify: "",
      idAppleMusic: "",
      language: "",
    },
    genres: [],
    languages: [],
    labels: [],
    labelSelected: null,
    hasLabel: false,
    typesOfPoetry: ["Monetização", "Bloqueio", "Rastreamento"],
    rules: {
      required: (value) => !!value || "Este campo é obrigatório.",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    },
    notification: {
      active: false,
      message: "",
      type: "",
    },
    userData: "",
    userEdit: {},
  }),
  created() {
    this.getUserData();
    this.listUserGroups();
  },
  methods: {
    getUserData() {
      this.loadingData = true;
      const userResponse = getUserById(this.$route.params.id);
      userResponse
        .then((res) => {
          const { data } = res.data;
          this.userData = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    listUserGroups() {
      getUserGroups()
        .then((res) => {
          const { data } = res.data;
          const groups = data.filter((group) => group.id != 1);
          this.userGroups = groups;
        })
        .catch((err) => {
          this.notification = {
            active: true,
            message: err.response.status,
            type: "error",
          };
          this.loading = false;
          this.hableSubmit = true;
        });
    },
    submit() {
      this.loading = true;
      this.hableSubmit = false;

      if (this.$refs.form.validate()) {
        const userUpdate = {
          first_name: this.userData.first_name,
          last_name: this.userData.last_name,
          email: this.userData.email,
          password: this.userData.password ? this.userData.password : null,
          metabase_password: this.metabase_password ? this.metabase_password : null,
          group_ids:
            this.selectedUserGroups.length > 0 ? this.selectedUserGroups : null,
        };
        putUser(userUpdate, this.$route.params.id)
          .then(() => {
            this.notification = {
              active: true,
              message: "Usuário atualizado com sucesso!",
              type: "success",
            };
            this.reset();
          })
          .catch((err) => {
            this.notification = {
              active: true,
              message: "Erro ao Atualizar: " + err.response.status,
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
            this.hableSubmit = true;
          });
      }
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
  },
};
</script>

<style>
.bg-card-content {
  background-color: rgb(0 0 0 / 41%);
  padding: 2rem;
  margin-bottom: 30px;
  height: auto;
}

.clear-button {
  width: 100%;
  max-width: 156px !important;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.submit-button {
  max-width: 156px !important;
}
</style>