import Vue from 'vue'

export async function getUserGroups() {
    const response = await Vue.prototype.$httpDados
    .get('/permissions/group', {headers: {'X-SESSION-ID': localStorage.token}});
    return response
}

// export function errorMessage(status) {
//     let message = "";
//     switch (status) {
//         case 409:
//             message = 'Este email já está sendo utilizado! Tente novamente com um email diferente.'
//             break;
        
//         case 500:
//             message = 'Algo deu errado. Já estamos resolvendo.'
//             break;
    
//         default:
//             message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
//             break;
//     }
//     return message
// }
