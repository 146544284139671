import Vue from 'vue'

export async function getCurrentUser() {
    const response = await Vue.prototype.$httpDados
    .get('/user/current', {headers: {'X-SESSION-ID': localStorage.token}});
    return response
}

export async function getUsers() {
    const response = await Vue.prototype.$httpDados
    .get('/user', {headers: {'X-SESSION-ID': localStorage.token}});
    return response
}

export async function getUserById(id) {
    const response = await Vue.prototype.$httpDados
    .get('/user/'+id, {headers: {'X-SESSION-ID': localStorage.token}});
    return response
}

export async function postUser(user) {
    const response = await Vue.prototype.$httpDados
    .post('/user', user);
    return response
}

export async function putUser(user, id) {
    const response = await Vue.prototype.$httpDados
    .put('/user/'+id, user);
    return response
}

export async function putUserPassword(user) {
    const response = await Vue.prototype.$httpDados
    .put('/user/current', user);
    return response
}

export async function deleteUser(id) {
    const response = await Vue.prototype.$httpDados
    .delete('/user/'+id);
    return response
}

export function errorMessage(status) {
    let message = "";
    switch (status) {
        case 400:
            message = 'Erro: Verifique os dados digitados no formulário.'
            break;

        case 409:
            message = 'Este email já está sendo utilizado! Tente novamente com um email diferente.'
            break;
        
        case 500:
            message = 'Algo deu errado. Já estamos resolvendo.'
            break;
    
        default:
            message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
            break;
    }
    return message
}
