<template>
  <div>
    <div>
      <app-loading :active="loading"></app-loading>
      <v-form ref="form" v-model="hableSubmit">
        <v-row align="center" justify="center">
          <div class="d-flex flex-column">
            <span class="type-profile">Alterar Senha</span>
          </div>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show ? 'text' : 'password'"
              name="password"
              dark
              label="Senha"
              @click:append="show = !show"
              class="fluid"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="mt-12 d-flex justify-center">
          <!-- <v-btn
            class="mr-4 clear-button"
            outlined
            dark
            color="white"
            height="40"
            @click="reset"
          >
            Limpar
          </v-btn> -->
          <v-btn
            :disabled="!hableSubmit"
            class="submit-button"
            height="40"
            @click="submit"
          >
            <span v-if="loading">Salvando</span>
            <span v-else>Salvar</span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <app-notification
      @notificationStatus="changeNotificationStatus"
      :active="notification.active"
      :type="notification.type"
      :message="notification.message"
    >
    </app-notification>
  </div>
</template>

<script>
import { putUserPassword } from "../services/User";
import PageLoading from "../components/PageLoading.vue";
import Notification from "../components/Notification.vue";
export default {
  components: {
    "app-loading": PageLoading,
    "app-notification": Notification,
  },
  data: () => ({
    show: false,
    loading: false,
    hableSubmit: true,
    firstName: "",
    secondName: "",
    email: "",
    password: "",
    type: "",
    typeUser: localStorage.tp_profile,
    types: [],
    userGroups: [],
    selectedUserGroups: [],
    basicInformation: {
      interpreter: "",
      mainGenre: "",
      secondaryGenre: "",
      typeOfPoetryOnYoutube: "",
      biography: "",
      idSpotify: "",
      idAppleMusic: "",
      language: "",
    },
    genres: [],
    languages: [],
    labels: [],
    labelSelected: null,
    hasLabel: false,
    typesOfPoetry: ["Monetização", "Bloqueio", "Rastreamento"],
    rules: {
      required: (value) => !!value || "Este campo é obrigatório.",
      capitalLetter: (value) =>
        /^(?=(?:.*?[A-Z]){1})/.test(value) ||
        "Sua senha deve conter no mínimo uma letra maiúscula.",
      number: (value) =>
        /^(?=(?:.*?[0-9]){1})/.test(value) ||
        "Sua senha deve conter no mínimo um caractere alfanumérico.",
      specialCharacter: (value) =>
        /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(
          value
        ) || "Sua senha deve conter no mínimo um caractere especial.",
      min: (v) =>
        v.length >= 6 || "Sua senha precisa ter no mínimo 6 caracteres",
    },
    notification: {
      active: false,
      message: "",
      type: "",
    },
    userData: "",
    userEdit: {},
  }),
  created() {},
  methods: {
    submit() {
      this.loading = true;
      this.hableSubmit = false;

      if (this.$refs.form.validate()) {
        const userUpdate = {
          password: this.password ? this.password : null,
        };
        putUserPassword(userUpdate)
          .then(() => {
            this.notification = {
              active: true,
              message: "Senha alterada com sucesso! Será necessário realizar login novamente.",
              type: "success",
            };
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/login";
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.notification = {
              active: true,
              message: "Erro ao alterar senha",
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
            this.hableSubmit = true;
          });
      }
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
  },
};
</script>

<style>
.bg-card-content {
  background-color: rgb(0 0 0 / 41%);
  padding: 2rem;
  margin-bottom: 30px;
  height: auto;
}

.clear-button {
  width: 100%;
  max-width: 156px !important;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.submit-button {
  max-width: 156px !important;
}
</style>