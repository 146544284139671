<template>
    <div>
        <v-form
            ref="form"
            v-model="hableSubmit"
        >
            <v-text-field
                v-model="newPassword"
                :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showNew ? 'text' : 'password'"
                name="new password"
                dark
                label="Nova Senha"
                counter
                @click:append="showNew = !showNew"
                class="fluid"
            ></v-text-field>
            <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showConfirm ? 'text' : 'password'"
                name="confirm password"
                dark
                label="Confirmar Senha"
                counter
                @click:append="showConfirm = !showConfirm"
                class="fluid"
            ></v-text-field>
            <div>
                <v-btn
                    :disabled="!hableSubmit"
                    dark
                    class="white--text submit-button mt-6"
                    large
                    @click="submit"
                >
                    <v-progress-circular v-if="loading" :indeterminate="true" size="24"></v-progress-circular>
                    <span v-else>Salvar</span>
                </v-btn>
            </div>
        </v-form>
        <v-snackbar v-model="alert.active" :multi-line="true" top dark :timeout="2000" transition="scale-transition"> 
            {{ alert.description }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="alert.active = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    data: () => ({
        loading: false,
        hableSubmit: true,
        showNew: false,
        showConfirm: false,
        newPassword: '',
        confirmPassword: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            min: v => v.length >= 6 || 'Sua senha precisa ter pelo menos 6 caracteres',
        },
        alert: {
            active: false,
            description: ''
        }
    }),
    methods: {
        submit() {
            this.loading = true;
            this.hableSubmit = false;
            if(this.$refs.form.validate()) {
                if(this.$route.name === 'Change Password') {
                    // update user password
                } else {
                    // reset user password
                }
            }
        },
    }
}
</script>