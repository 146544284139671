<template>
  <div>
    <app-profile-header></app-profile-header>
    <v-row>
      <v-col v-if="profileType == 'Client'">
        <app-list-labels
          v-on:labelSelected="labelSelected = $event"
        ></app-list-labels>
      </v-col>
      <v-col v-if="profileType == 'Label' || idLabel">
        <app-list-artists :idLabel="idLabel"></app-list-artists>
      </v-col>
    </v-row>
    <v-row>
      <div class="container">
        <iframe :src="dashboardUrl" width="100%" height="2750" frameborder="0">
        </iframe>
      </div>
    </v-row>
  </div>
</template>

<script>
import ListLabels from "../components/ListLabels.vue";
import ListArtists from "../components/ListArtists.vue";
import ProfileHeader from "../components/ProfileHeader";
import { getEmbeddableDashboard, listDashboards } from "../services/Dashboard";

export default {
  components: {
    "app-profile-header": ProfileHeader,
    "app-list-labels": ListLabels,
    "app-list-artists": ListArtists,
  },
  data: () => ({
    profileType: localStorage.tp_profile,
    labelSelected: "",
    idLabel: "",
    dashboardUrl: "",
  }),
  created() {
    this.loadDashboard();
  },
  methods: {
    loadDashboard() {
      this.loading = true;
      const dashboardId = this.$route.params.id;
      if (!dashboardId) {
        const resp = listDashboards();
        resp
          .then((res) => {
            const { data } = res.data;
            if (data.length > 0) {
                // window.location.href = '/home/'+data[0].id
              const response = getEmbeddableDashboard(data[0].id);
              response
                .then((res) => {
                  const { data } = res.data;
                  this.dashboardUrl = data.url;
                })
                .catch((err) => {
                  this.notification = {
                    active: true,
                    message: err,
                    type: "error",
                  };
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          })
          .catch((err) => {
            this.notification = {
              active: true,
              message: err,
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const response = getEmbeddableDashboard(dashboardId);
        response
          .then((res) => {
            const { data } = res.data;
            this.dashboardUrl = data.url;
          })
          .catch((err) => {
            this.notification = {
              active: true,
              message: err,
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    $route() {
      this.loadDashboard();
    },
  },
};
</script>

<style>
</style>