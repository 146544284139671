<template>
    <div>
        <img src="../assets/logo-hitbel.svg" alt="">
        <h2 class="white--text text-uppercase mt-9 title-auth">{{title}}</h2>
        <p class="mt-2 description-auth">{{description}}</p>
    </div>
</template>

<script>
export default {
    name: 'app-header-auth',
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        description: {
            type: String,
            default: '',
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .title-auth {
        font-size: 1.625rem;
        font-weight: bold;
        line-height: 1.35;
    }
    .description-auth {
        font-size: 0.825rem;
        color: #c4c4c4;
    }
</style>