import Vue from 'vue'

export async function postUserCredentials(login, password) {
    let params = {};

    params = {
        "username": login,
        "password": password
    }


    const response = await Vue.prototype.$httpDados
        .post('/session', params);
    return response
}

export async function logout() {
    const response = await Vue.prototype.$httpDados.delete('/session');
    return response
}

export function errorMessage(status) {
    let message = "";
    switch (status) {
        case 401:
            message = 'Usuário e/ou senha inválidos.'
            break;

        case 500:
            message = 'Algo deu errado. Já estamos resolvendo.'
            break;

        default:
            message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
            break;
    }
    return message
}
