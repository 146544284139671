import Vue from 'vue'

export async function getEmbeddableDashboard(dashboardId) {
    const response = await Vue.prototype.$httpDados
        .get('/dashboard/'+ dashboardId + '/embed');
    return response
}

export async function listDashboards() {
    const response = await Vue.prototype.$httpDados
        .get('/dashboard');
    return response
}