<template>
    <div class="bg-auth d-flex align-center">
        <v-container>
            <v-row>
                <v-col sm="12" md="6" lg="4">
                    <app-header-auth :title="header.title" :description="header.description" class="mb-12"></app-header-auth>
                    <app-recover-password-form-auth class="pt-12"></app-recover-password-form-auth>
                </v-col>
            </v-row>
        </v-container>
        <app-image-background-auth></app-image-background-auth>
    </div>
</template>

<script>
import AuthHeader from '../components/AuthHeader.vue'
import AuthImageBackground from '../components/AuthImageBackground.vue'
import AuthRecoverPasswordForm from '../components/AuthRecoverPasswordForm.vue'

export default {
    components: { 
        'app-header-auth': AuthHeader,
        'app-recover-password-form-auth': AuthRecoverPasswordForm,
        'app-image-background-auth': AuthImageBackground
     },
    data: () => ({
        header: {
            title: 'Esqueceu a senha?',
            description: 'Digite seu e-mail cadastrado para receber as instruções de como redefinir sua senha.' 
        }
    })
}
</script>

<style lang="scss">
    @import "../sass/__auth.scss"; 
</style>