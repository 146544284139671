<template>
  <div>
    <app-loading :active="loading"></app-loading>
    <v-form ref="form" v-model="hableSubmit">
      <v-text-field
        v-model="login"
        :rules="[rules.required, rules.email]"
        label="Login"
        required
        dark
        class="fluid"
        @keyup.enter="submit"
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[
          rules.required,
          rules.min,
          rules.capitalLetter,
          rules.number,
          rules.specialCharacter,
        ]"
        :type="show ? 'text' : 'password'"
        name="password"
        dark
        label="Senha"
        @click:append="show = !show"
        class="fluid"
        @keyup.enter="submit"
      ></v-text-field>
      <!-- <router-link to="/esqueci-minha-senha" class="navigate-text float-right"
        >Esqueceu sua senha?</router-link
      > -->
      <div>
        <v-btn
          :disabled="!hableSubmit"
          class="submit-button mt-12"
          height="60"
          @click="submit"
        >
          <span v-if="loading">Entrando</span>
          <span v-else>Entrar</span>
        </v-btn>
      </div>
    </v-form>
    <app-notification
      @notificationStatus="changeNotificationStatus"
      :active="notification.active"
      :type="notification.type"
      :message="notification.message"
    >
    </app-notification>
  </div>
</template>

<script>
import { postUserCredentials, errorMessage } from "../services/Auth";
import PageLoading from "./PageLoading.vue";
import Notification from "./Notification.vue";
import { getCurrentUser } from "../services/User";

export default {
  components: {
    "app-loading": PageLoading,
    "app-notification": Notification,
  },
  data: () => ({
    loading: false,
    hableSubmit: true,
    show: false,
    login: "",
    password: "",
    rules: {
      required: (value) => !!value || "Este campo é obrigatório.",
      min: (v) =>
        v.length >= 6 || "Sua senha precisa ter no mínimo 6 caracteres",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    },
    notification: {
      active: false,
      message: "",
      type: "",
    },
  }),
  methods: {
    submit() {
      this.loading = true;
      this.hableSubmit = false;
      if (this.$refs.form.validate()) {
        const authResponse = postUserCredentials(
          this.login,
          this.password,
          null,
          null
        );
        authResponse
          .then((res) => {
            const { data } = res.data;
            localStorage.token = data.id;
            const userResponse = getCurrentUser();
            userResponse
              .then((res) => {
                const { data } = res.data;
                localStorage.is_superuser = data.metabase_user.is_superuser;
                window.location.href = "/";
              })
              .catch(() => {
                this.notification = {
                  active: true,
                  message: errorMessage(401),
                  type: "error",
                };
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.notification = {
              active: true,
              message: errorMessage(401),
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
            this.hableSubmit = true;
          });
      }
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
  },
};
</script>