<template>
    <div class="bg-auth d-flex align-center">
        <v-container>
            <v-row>
                <v-col sm="12" md="6" lg="4">
                    <app-header-auth :title="header.title" :description="header.description" class="mb-12"></app-header-auth>
                    <app-login-form-auth class="pt-12"></app-login-form-auth>
                </v-col>
            </v-row>
        </v-container>
        <app-image-background-auth></app-image-background-auth>
    </div>
</template>

<script>
import AuthHeader from '../components/AuthHeader.vue'
import AuthImageBackground from '../components/AuthImageBackground.vue'
import AuthLoginForm from '../components/AuthLoginForm.vue'

export default {
    components: { 
        'app-header-auth': AuthHeader,
        'app-login-form-auth': AuthLoginForm,
        'app-image-background-auth': AuthImageBackground
    },
    data: () => ({
        header: {
            title: 'Seja Bem-Vindo',
            description: 'Estamos aqui para apoiar você, lançando sua mensagem ao alvo.' 
        }
    })
}
</script>

<style lang="scss">
    @import "../sass/__auth.scss"; 
</style>