<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon size="24px" v-bind="attrs" v-on="on" @click="setData">mdi-pencil</v-icon>
      </template>
      <v-card color="#071c33" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <div class="d-flex justify-space-between align-center">
                  <span class="headline white--text">Detalhes do Selo</span>
                  <v-icon size="24px" class="pointer" @click="dialog = false">mdi-close</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" class="mt-6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                     label="Nome do selo" v-model="name" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex-wrap align-center">
          <v-spacer class="d-none d-md-block"></v-spacer>
          <v-btn
            v-if="!deleteConfirm"
            color="#ef5350"
            outlined
            class="mb-4"
            @click="deleteConfirm = true"
            dark
          >
            <span>Excluir selo</span>
          </v-btn>
          <v-btn v-else color="#ef5350" class="mb-4" @click="deleteLabel()" dark>
            <span>CONFIRMAR EXCLUSÃO</span>
          </v-btn>
          <v-btn
            :disabled="!valid"
            @click="updateLabel()"
            class="submit-button mr-2 mb-4"
          >
            <v-progress-circular v-if="loading" :indeterminate="true" size="24"></v-progress-circular>
            <span v-else>Salvar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { putLabel, deleteLabel } from "../services/Label"
export default {
  props: {
    label: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    name: '',
    deleteConfirm: false,
    loading: false,
    notification: {
        active: false,
        description: '',
        type: '',
        closeText: ''
    },
    valid: true,
    rules: {
        required: value => !!value || 'Este campo é obrigatório.',
    },
    dialog: false
  }),
  methods: {
    setData() {
        console.log(this.label);
        this.name = this.label.no_label;
    },
    updateLabel() {
        const label = {
            "id": this.label.id_label,
            "no_label": this.name,
        };
        const reqLabel = putLabel(label);
        reqLabel
        .then( () => {
            this.notification = {
                active: true,
                message: 'Selo editado com sucesso.',
                type: 'success',
            },
            this.$emit( 'notification', this.notification );
        })
        .catch(() => {
            (this.notification = {
                active: true,
                message: "Houve um erro ao editar este selo, tente novamente.",
                type: "error",
            }),
            this.$emit("notification", this.notification);
        })
        .finally(() => {
            (this.loading = false),
            (this.valid = true),
            (this.dialog = false),
            (this.deleteConfirm = false),
            this.$emit("getData");
        });
        },
        deleteLabel() {
            const reqLabel = deleteLabel(this.label.id_label);
            reqLabel
            .then(() => {
            (this.notification = {
                active: true,
                message: "Selo excluído com sucesso.",
                type: "success"
            }),
                this.$emit("notification", this.notification);
            })
            .catch(() => {
            (this.notification = {
                active: true,
                message: "Houve um erro ao excluir este selo, tente novamente.",
                type: "error",
            }),
                this.$emit("notification", this.notification);
            })
            .finally(() => {
                (this.loading = false),
                (this.valid = true),
                (this.dialog = false),
                (this.deleteConfirm = false),
                this.$emit("getData");
            });
        }
    }
};
</script>

<style scoped>
    .submit-button {
        width: 150px !important;
    }
</style>