import Vue from 'vue'
import axios from 'axios'
import router from '../router';
const baseURLApi = 'https://api.dados.hitbel.com.br';
// const baseURLApi = 'http://localhost:8081';


function createApiHeader(token) {
    const headers = {
        'access-control-allow-headers': '*',
        'access-control-allow-methods': '*',
        'access-control-allow-origin': '*',
        'content-type': 'application/json'
    }
    if (token) {
        headers['X-SESSION-ID'] = token;
    }
    return headers
}

const header = createApiHeader(localStorage.token);

const apiDados = axios.create({
    baseURL: baseURLApi,
    headers: header
});

apiDados.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        if (!window.location.href.includes('/login')) {
            router.push('/login');
        }
    }
    return Promise.reject(error);
});

Vue.use({
    install(Vue) {
        Vue.prototype.$httpDados = apiDados
    }
})