<template>
    <div>
        <h3 class="white--text">CADASTRO</h3>
        <div class="mt-12">
            <app-register-user-form></app-register-user-form>
        </div>
        <div class="mt-12">
            <app-list-artists></app-list-artists>
        </div>
    </div>
</template>

<script>
import RegisterUserFormVue from '../components/RegisterUserForm.vue'
import ListArtists from '../components/ListArtists.vue'

export default {
    components: {
        'app-register-user-form': RegisterUserFormVue,
        'app-list-artists': ListArtists
    }
}
</script>

<style>

</style>