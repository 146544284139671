<template>
    <div>
        <img src="../assets/people-bg-auth.png" alt="img-bg-auth" id="img-bg-auth">
    </div>
</template>

<script>
export default {

}
</script>

<style>
    #img-bg-auth {
        position: fixed;
        bottom: 0;
        height: 90%;
        right: -15%;
        width: auto;
    }
    @media only screen and (max-width: 960px) {
        #img-bg-auth {
            display: none;
        }
    }
    @media only screen and (min-width: 1263px) {
        #img-bg-auth {
            right: 0;
        }
    }
    @media only screen and (min-width: 1750px) {
        #img-bg-auth {
            right: 15%;
        }
    }
</style>