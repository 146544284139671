<template>
  <div>
    <app-loading :active="loading"></app-loading>
    <v-form ref="form" v-model="hableSubmit">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="firstName"
            :rules="[rules.required]"
            label="Primeiro Nome"
            required
            dark
            class="fluid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="secondName"
            :rules="[rules.required]"
            label="Segundo Nome"
            required
            dark
            class="fluid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="Email"
            required
            dark
            class="fluid"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="password"
            dark
            label="Senha"
            @click:append="show = !show"
            class="fluid"
            @keyup.enter="submit"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" md="3">
          <v-text-field
            v-model="metabase_password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="metabase_password"
            dark
            label="Senha Metabase (Apenas para acesso ao Metabase)"
            @click:append="show = !show"
            class="fluid"
            @keyup.enter="submit"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" md="3">
          <v-select
            v-model="selectedUserGroups"
            :items="userGroups"
            item-text="name"
            item-value="id"
            label="Grupo de Usuário"
            multiple
            dark
          >
          </v-select>
        </v-col>
      </v-row>
      <div class="mt-12 d-flex justify-end">
        <v-btn
          class="mr-4 clear-button"
          outlined
          dark
          color="white"
          height="40"
          @click="reset"
        >
          Limpar
        </v-btn>
        <v-btn
          :disabled="!hableSubmit"
          class="submit-button"
          height="40"
          @click="submit"
        >
          <span v-if="loading">Salvando</span>
          <span v-else>Salvar</span>
        </v-btn>
      </div>
    </v-form>
    <app-notification
      @notificationStatus="changeNotificationStatus"
      :active="notification.active"
      :type="notification.type"
      :message="notification.message"
    >
    </app-notification>
  </div>
</template>

<script>
import { postUser, errorMessage } from "../services/User";
import { getUserGroups } from "../services/UserGroups";
import PageLoading from "./PageLoading.vue";
import Notification from "./Notification.vue";

export default {
  components: {
    "app-loading": PageLoading,
    "app-notification": Notification,
  },
  created() {
    this.listUserGroups();
  },
  data: () => ({
    loading: false,
    hableSubmit: true,
    firstName: "",
    secondName: "",
    email: "",
    password: "",
    metabase_password: "",
    type: "",
    typeUser: localStorage.tp_profile,
    types: [],
    userGroups: [],
    selectedUserGroups: null,
    basicInformation: {
      interpreter: "",
      mainGenre: "",
      secondaryGenre: "",
      typeOfPoetryOnYoutube: "",
      biography: "",
      idSpotify: "",
      idAppleMusic: "",
      language: "",
    },
    genres: [],
    languages: [],
    labels: [],
    labelSelected: null,
    hasLabel: false,
    typesOfPoetry: ["Monetização", "Bloqueio", "Rastreamento"],
    rules: {
      required: (value) => !!value || "Este campo é obrigatório.",
      capitalLetter: (value) =>
        /^(?=(?:.*?[A-Z]){1})/.test(value) ||
        "Sua senha deve conter no mínimo uma letra maiúscula.",
      number: (value) =>
        /^(?=(?:.*?[0-9]){1})/.test(value) ||
        "Sua senha deve conter no mínimo um caractere alfanumérico.",
      specialCharacter: (value) =>
        /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(
          value
        ) || "Sua senha deve conter no mínimo um caractere especial.",
      min: (value) =>
        value.length >= 6 || "Sua senha precisa ter no mínimo 6 caracteres",
    },
    notification: {
      active: false,
      message: "",
      type: "",
    },
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
    },

    listUserGroups() {
      getUserGroups()
        .then((res) => {
          const { data } = res.data;
          const groups = data.filter((group) => group.id != 1);
          this.userGroups = groups;
        })
        .catch((err) => {
          this.notification = {
            active: true,
            message: err.response.status,
            type: "error",
          };
          this.loading = false;
          this.hableSubmit = true;
        });
    },

    submit() {
      this.loading = true;
      this.hableSubmit = false;

      if (this.$refs.form.validate()) {
        const user = {
          first_name: this.firstName,
          last_name: this.secondName,
          email: this.email,
          password: this.password,
          metabase_password: this.metabase_password
            ? this.metabase_password
            : null,
          group_ids: this.selectedUserGroups,
        };
        const postUserResp = postUser(user);
        postUserResp
          .then((data) => {
            console.log(data);
            this.notification = {
              active: true,
              message: "Usuário cadastrado com sucesso!",
              type: "success",
            };
            this.reset();
          })
          .catch((err) => {
            this.notification = {
              active: true,
              message: errorMessage(err.response.status),
              type: "error",
            };
          })
          .finally(() => {
            this.loading = false;
            this.hableSubmit = true;
          });
      }
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.clear-button {
  width: 100%;
  max-width: 156px !important;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.submit-button {
  max-width: 156px !important;
}
</style>