<template>
    <div>
        <v-form
        ref="form"
        v-model="hableSubmit"
        >
            <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="Email"
                required
                dark
                class="fluid"
            ></v-text-field>
            <div class="d-flex align-center flex-column">
                <v-btn
                    :disabled="!hableSubmit"
                    dark
                    class="white--text submit-button mt-6 mb-2"
                    large
                    @click="submit"
                >
                    <v-progress-circular v-if="loading" :indeterminate="true" size="24"></v-progress-circular>
                    <span v-else>Enviar</span>
                </v-btn>
                <router-link to="/login" class="mt-2 navigate-text">Voltar para o login</router-link>
            </div>
        </v-form>
        <v-snackbar v-model="alert.active" :multi-line="true" top dark :timeout="2000" transition="scale-transition"> 
            {{ alert.description }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="alert.active = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
// import { postUserCredentials } from '../services/Auth';

export default {
    data: () => ({
        loading: false,
        hableSubmit: true,
        email: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            email: v => /.+@.+\..+/.test(v) || 'E-mail inválido'
        },
        alert: {
            active: false,
            description: ''
        }
    }),
    methods: {
        submit() {
            this.loading = true;
            this.hableSubmit = false;
            if(this.$refs.form.validate()) {
                // const authResponse = postCredentials(this.email);
                // authResponse.then(res => {
                //    console.log(res);
                // })
                // .catch(err => {
                //     this.alert.active = true;
                //     this.alert.description = err.message
                // })
                // .finally(() =>{
                //     this.loading = false;
                //     this.hableSubmit = true;
                // })
            }
        },
    }
}
</script>