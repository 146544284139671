<template>
    <div>
        <h3 class="white--text mt-6">SELOS CADASTROS</h3>
        <!-- <div class="d-flex flex-wrap mt-3">
            <div v-for="label in labels" :key="label.id_label">
                <app-card-user :userName="label.no_label" class="mr-6 mb-6" :description="label.qt_artists + ' Artistas'" :avatarUrl="label.avatar_irl"></app-card-user>
            </div>
        </div> -->
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Selo"
                single-line
                hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                v-model="selected"
                :loading="loading" loading-text="Carregando..."
                :headers="headers"
                :items="desserts"
                :search="search"
                :single-select="true"
                item-key="id_label"
                show-select
                class="elevation-1 bg-table"
            >
                <template v-slot:item.actions="{ item }">
                    <app-label-edit :label="item" v-on:notification="notification = $event" @getData="getLabels"></app-label-edit>
                </template>
            </v-data-table>
        </v-card>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>

// import CardUser from './CardUser.vue'
import { getLabels } from '../services/Label'
import Notification from './Notification.vue';
import EditLabel from './EditLabel.vue'

export default {
    components: {
        // 'app-card-user': CardUser
        'app-notification': Notification,
        'app-label-edit': EditLabel
    },
    created() {
        this.getLabels();
    },
    data: () => ({
        notification: {
            active: false,
            message: '',
            type: ''
        },
        labels: [],
        loading: false,
        headers:  [
          { text: 'Nome do Selo', value: 'no_label', sortable: true, filterable: true },
          { text: 'Quantidade de Artistas', align: 'center', value: 'qt_artists', sortable: true, filterable: true },
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        desserts: [],
        selected: [],
        search: ''
    }),
    watch: {
        selected: function() {
            this.$emit('labelSelected', this.selected);
        }
    },
    methods: {
        getLabels() {
            this.loading = true;
            const labelResponse = getLabels();
            labelResponse
            .then(res => {
                const { data } = res.data;
                this.desserts = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style>
    .bg-table {
        background-color: #071c33 !important;
    }
</style>