<template>
    <router-link 
            :to="{ name: navigateItem, params: { id: navigateParam } }" 
            v-slot="{ isActive, navigate }"
        >
        <div
            class="item-nav"
            @click="navigate"
            v-bind:class="{'active': isActive }"
        >
              <v-icon class="mr-3" size="24" :color="isActive ? '#994995': '#aed0d9'">{{ icon }}</v-icon>
              <span>{{ item }} </span>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        navigateItem: {
            type: String,
            required: true
        },
        navigateParam: {
            type: Number,
            required: false
        },
        icon: {
            type: String,
            required: true
        },
        item: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .item-nav {
        height: 60px;
        background-color: #14274e;
        display: flex;
        align-items: center;
        padding-left: 90px;
        transition: filter .2s;
        
        span {
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.33;
            color: #aed0d9;
        }
    }

    .item-nav.active {   
        padding-left: 80px;
        border-left: 10px solid #994995;
        background-color: #183061;

        span {
            color: #ffffff;
        }
    }

    .item-nav:hover {
        filter: brightness(0.95);
        cursor: pointer;

    }
</style>