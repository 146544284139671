<template>
    <div id="main-content">    
        <app-navigation></app-navigation>
        <v-container class="main-content">
            <v-row>
                <v-col class="mt-6">
                    <router-view />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Navigation from '../components/Navigation.vue'

export default {
    components: { 
        'app-navigation': Navigation
    }
}
</script>

<style lang="scss" scoped>
    #main-content {
        position: relative;
        background-color: #071d35;
        min-height: 100vh;
    }
    #main-content:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background-image: url("../assets/main-bg.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% auto;
    }
    .main-content {
        position: relative;
    }
</style>