<template>
  <div>
    <h3 class="white--text mt-6">USUÁRIOS CADASTRADOS</h3>
    <!-- <div class="d-flex flex-wrap mt-3">
            <div v-for="label in labels" :key="label.id_label">
                <app-card-user :userName="label.no_label" class="mr-6 mb-6" :description="label.qt_artists + ' Artistas'" :avatarUrl="label.avatar_irl"></app-card-user>
            </div>
        </div> -->
    <v-card dark class="mt-4" color="#071c33">
      <v-col md="6" class="mb-4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Usuário"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-data-table
        :loading="loading"
        loading-text="Carregando..."
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        class="elevation-1 bg-table"
      >
        <template v-slot:item.actions="{ item }">
          <router-link :to="'/artista/editar/' + item.id">
            <v-icon size="16">mdi-square-edit-outline</v-icon>
          </router-link>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-icon size="16" @click="removeUser(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <app-notification
      @notificationStatus="changeNotificationStatus"
      :active="notification.active"
      :type="notification.type"
      :message="notification.message"
    >
    </app-notification>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h8"
          >Confirma a remoção do usuário {{this.deletedItem.first_name}}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >Apagar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import CardUser from './CardUser.vue'
import { getUsers, deleteUser } from "../services/User";
import Notification from "./Notification.vue";
// import EditLabel from './EditLabel.vue'

export default {
  components: {
    // 'app-card-user': CardUser
    "app-notification": Notification,
    // 'app-label-edit': EditLabel
  },
  props: {},
  created() {
    this.getUsers();
  },
  data: () => ({
    deletedIndex: -1,
    deletedItem: {},
    defaultItem: {},
    dialogDelete: false,
    notification: {
      active: false,
      message: "",
      type: "",
    },
    artists: [],
    loading: false,
    headers: [
      { text: "Nome", value: "first_name", sortable: true, filterable: true },
      {
        text: "Sobrenome",
        value: "last_name",
        sortable: true,
        filterable: true,
      },
      { text: "Email", align: "center", value: "email", sortable: true },
      {
        text: "Editar",
        align: "center",
        value: "actions",
        sortable: false,
        filterable: false,
      },
      {
        text: "Excluir",
        align: "center",
        value: "delete",
        sortable: false,
        filterable: false,
      },
    ],
    desserts: [],
    search: "",
  }),
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    getUsers() {
      this.loading = true;
      const userResponse = getUsers();
      userResponse
        .then((res) => {
          const { data } = res.data;
          this.desserts = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeUser(user) {
      this.deletedIndex = this.desserts.indexOf(user);
      this.deletedItem = Object.assign({}, user);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },
    deleteItemConfirm() {
      this.loading = true;
      const deleteResponse = deleteUser(this.deletedItem.id);
      deleteResponse
        .then(() => {
          this.notification = {
            active: true,
            message: "Usuário apagado com sucesso!",
            type: "success",
          };
        })
        .catch((err) => {
          this.notification = {
            active: true,
            message: "Erro ao Apagar: " + err.response.status,
            type: "error",
          };
        })
        .finally(() => {
          this.loading = false;
        });
      this.desserts.splice(this.deletedIndex, 1);
      this.closeDelete();
    },
    changeNotificationStatus() {
      this.notification = {
        active: false,
        message: "",
        type: "",
      };
    },
  },
};
</script>

<style>
.bg-table {
  background-color: #071c33 !important;
}
</style>