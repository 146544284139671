<template>
  <div>
    <v-app-bar color="#ffffff00" dark flat elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img src="../assets/logo-hitbel.svg" alt="" height="30px" class="ml-8" />
      <v-spacer></v-spacer>
      <router-link :to="{name: 'Change Password'}">
        <v-btn icon>
          <v-avatar>
            <v-icon dark size="36"> mdi-key </v-icon>
          </v-avatar>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
      overlay-color="#ffffff00"
      class="nav-drawer"
      width="350"
    >
      <!-- <div class="nav-drawer-top">-->
      <div class="d-flex justify-center align-center my-3">
        <img
          src="../assets/logo-hitbel.svg"
          alt=""
          height="30px"
          class="ma-12"
        />
      </div>
      <!-- <ul>
          <li v-id="typeUser">
              <a :href="'cadastro-de-usuario'">Cadastrar Usuário</a>
          </li>
          <li v-for="d in dashboards" :key="d.id">
              <a :href="'home/'+d.id">{{d.name}}</a>
          </li>
      </ul> -->
      <app-navigation-item
        v-if="typeUser == 'true'"
        :navigateItem="'Register'"
        icon="mdi-view-dashboard"
        :item="'Cadastrar Usuário'"
      ></app-navigation-item>
      <app-navigation-item
        v-bind:key="d.id"
        v-for="d in dashboards"
        :navigateItem="'HomeId'"
        :navigateParam="d.id"
        icon="mdi-view-dashboard"
        :item="d.name"
      ></app-navigation-item>
      <!--</div>-->
      <!--<div class="nav-drawer-bottom">-->
      <div class="d-flex justify-center my-3">
        <app-logout icon="mdi-logout" text="Sair"></app-logout>
      </div>
      <!--</div>-->
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavigationItem from "../components/NavigationItem.vue";
import Logout from "../components/Logout.vue";
import { listDashboards } from "../services/Dashboard";

export default {
  components: {
    "app-navigation-item": NavigationItem,
    "app-logout": Logout,
  },
  data: () => ({
    drawer: false,
    group: null,
    typeUser: localStorage.is_superuser,
    dashboards: [],
  }),
  created() {
    this.loadMenu();
  },
  methods: {
    loadMenu() {
      const resp = listDashboards();
      resp
        .then((res) => {
          const { data } = res.data;
          this.dashboards = data;
        })
        .catch((err) => {
          this.notification = {
            active: true,
            message: err,
            type: "error",
          };
        })
        .finally(() => {});
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.nav-drawer {
  background-image: linear-gradient(to bottom, #0f1f3f, #09152c);
  // max-height: 100vh;
  // position: fixed;
}
// .v-navigation-drawer__content {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     max-height: 100vh;
// }
</style>