import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'
import RecoverPassword from './views/RecoverPassword.vue'
import RedefinePassword from './views/RedefinePassword.vue'
// import CompleteRegistration from './views/CompleteRegistration.vue'

import Main from './views/Main.vue'
import Home from './views/Home.vue'
import PageUser from './views/PageUser.vue'
import EditUser from './views/EditUser.vue'
import EditUserPassword from './views/EditUserPassword.vue'
import Register from './views/Register.vue'
// import RegisterMetadata from './views/RegisterMetadata.vue'
// import ErrorNotFound from './views/ErrorNotFound.vue'
// import ErrorInternalServer from './views/ErrorInternalServer.vue'
// import ErrorForbidden from './views/ErrorForbidden.vue'
// import ErrorBadGateway from './views/ErrorBadGateway.vue'

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login - Hitbel Dados'
    }
  },
  {
    path: '/esqueci-minha-senha',
    name: 'Recover Password',
    component: RecoverPassword,
    meta: {
      title: 'Esqueci minha senha - Hitbel Dados'
    }
  },
  {
    path: '/redefinir-senha/:token',
    name: 'Redefine Password',
    component: RedefinePassword,
    meta: {
      title: 'Redefinir senha - Hitbel Dados'
    }
  },
  // { 
  //   path: '/completar-cadastro/:token/:id',
  //   name: 'Complete Registration',
  //   component: CompleteRegistration,
  //   meta: {
  //     title: 'Completar Cadastro - Hitbel Dados'
  //   }
  // },
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: { name: 'Home' },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Página Inicial - Hitbel Dados'
        }
      },
      {
        path: '/home/:id',
        name: 'HomeId',
        component: Home,
        meta: {
          title: 'Página Inicial - Hitbel Dados'
        }
      },
      {
        path: '/artista/:id',
        name: 'Page User',
        component: PageUser,
        meta: {
          title: 'Listar - Hitbel Dados'
        }
      },
      {
        path: '/artista/editar/:id',
        name: 'Page User Edit',
        component: EditUser,
        meta: {
          title: 'Listar - Hitbel Dados'
        }
      },
      {
        path: '/cadastro-de-usuario',
        name: 'Register',
        component: Register,
        meta: {
          title: 'Cadastrar Usuário - Hitbel Dados'
        }
      },
      {
        path: '/trocar-senha',
        name: 'Change Password',
        component: EditUserPassword,
        meta: {
          title: 'Redefinir senha - Hitbel Dados'
        }
      },
      // {
      //   path: '/cadastro-de-metadados',
      //   name: 'Register Metadata',
      //   component: RegisterMetadata,
      //   meta: {
      //     title: 'Cadastrar Dados - Hitbel Dados'
      //   }
      // }
    ]
  }
]

const router = new Router({
  mode: 'history',
  routes: routes
})

function routeValidade(routeName) {
  const routesNoAuth = ["Login", "Recover Password", "Redefine Password", "Complete Registration"];
  const needAuth = routesNoAuth.indexOf(routeName) > -1;
  const isAuthenticated = localStorage.token != null ? true : false;
  const validRoute = (!needAuth && !isAuthenticated) ? true : false;

  return validRoute
}

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.token != null ? true : false;
  if (routeValidade(to.name)) next({ name: 'Login' })
  else if (to.name == 'Login' && isAuthenticated) next({ name: 'Main' })
  else next()
})

export default router;