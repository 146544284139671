<template>
    <div>
        <div class="d-flex align-center header-profile">
            <!-- <v-avatar
                size="148"
                class="mr-8"
                color="#fff"
            >
                <img v-if="profilePicture" :src="profilePicture" alt="">
                <v-icon v-else size="120" color="#071d35">mdi-account</v-icon>
            </v-avatar> -->
            <div class="d-flex align-end justify-space-between fluid">
                <div class="d-flex flex-column">
                    <span class="type-profile">Usuário</span>
                    <span class="name-profile">{{userData.first_name + " " + userData.last_name}}</span>
                </div>
                <div>
                     <router-link :to="'/artista/editar/'+ userData.id">
                        <v-icon size="32" color="grey" class="mr-4">
                            mdi-square-edit-outline
                        </v-icon>
                    </router-link>
                    <v-icon size="32" color="grey">
                        mdi-delete
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUserById } from "../services/User"
export default {
    data: () => ({
        userData: ''
    }),
    created() {
        this.getArtistData();
    },
    methods: {
        getArtistData() {
            this.loadingData = true;
            const userResponse = getUserById(this.$route.params.id);
            userResponse
            .then(res => {
                const { data } = res.data;
                this.userData = data;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loadingData = false;
            })
        },
    }
}

</script>

<style>
    .fluid {
        width: 100%;
    }
    .header-profile {
        padding-bottom: 12px;
        border-bottom: 1px solid #fff;
    }

    .type-profile {
        text-transform: uppercase;
        font-size: 1.125rem;
        color: #fff;
        line-height: 1.33;
    }

    .name-profile {
        font-size: 2.875rem;
        font-weight: bold;
        line-height: 1.3;
        color: #eb6573;
        text-transform: uppercase;
    }
</style>